import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Box component serves as a wrapper component for most layout related needs. Use Box to set values such as `}<inlineCode parentName="p">{`display`}</inlineCode>{`,  `}<inlineCode parentName="p">{`width`}</inlineCode>{`, `}<inlineCode parentName="p">{`height`}</inlineCode>{`, and more. See the LAYOUT section of our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` documentation for the full list of available props. In practice, this component is used frequently as a wrapper around other components to achieve Box Model related styling.`}</p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live live",
        "live": true
      }}>{`<Box>
 Box can be used to create both <Box as="span" bg="green.1">inline</Box> and
 <Box bg="blue.1">block-level elements,</Box>
 <Box bg="turquoise.1" width={[1, 1, 1/2]}>elements with fixed or responsive width and height,</Box>
 <Box bg="yellow.0" p={4} mt={2}>and more!</Box>
</Box>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`Box components get the `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{`, `}<inlineCode parentName="p">{`LAYOUT`}</inlineCode>{`, and `}<inlineCode parentName="p">{`FLEX`}</inlineCode>{` categories of system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`div`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sets the HTML tag for the component`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      